import React from "react";
import Button from "../button";
import style from "./pricing-card.module.scss";
import popular from '@/assets/icons/popular-badge.svg';
import Image from "next/image";
const PricingCard = ({
  pack,
  price,
  isPopular,
  features,
  onBtnClick,
  btnText
}) => {
  return <div className={style.cardWrapper} data-sentry-component="PricingCard" data-sentry-source-file="index.jsx">
      {isPopular && <Image src={popular} className={style.popular} />}
      <div className={style.pack}>{pack}</div>
      <div className={style.price}>
        <span>
          <span className={style.numberText}>{price}</span> Per Session
        </span>
      </div>
      <div className={style.features}>
        {features.map((ele, index) => <div key={index} className={style.featureRow}>
            <div>
              <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M9.33282 16.2835L5.41382 12.3645C5.22629 12.1771 4.97198 12.0718 4.70682 12.0718C4.44165 12.0718 4.18735 12.1771 3.99982 12.3645C3.81235 12.5521 3.70703 12.8064 3.70703 13.0715C3.70703 13.3367 3.81235 13.591 3.99982 13.7785L7.91882 17.6975C8.10455 17.8833 8.32506 18.0307 8.56776 18.1313C8.81047 18.2319 9.07061 18.2836 9.33332 18.2836C9.59603 18.2836 9.85617 18.2319 10.0989 18.1313C10.3416 18.0307 10.5621 17.8833 10.7478 17.6975L19.9998 8.44555C20.1873 8.25803 20.2926 8.00372 20.2926 7.73855C20.2926 7.47339 20.1873 7.21908 19.9998 7.03156C19.8123 6.84408 19.558 6.73877 19.2928 6.73877C19.0277 6.73877 18.7733 6.84408 18.5858 7.03156L9.33282 16.2835Z" fill="#254956" />
              </svg>
            </div>
            <div>{ele}</div>
          </div>)}
      </div>
      <Button label={btnText} data-sentry-element="Button" data-sentry-source-file="index.jsx" />
    </div>;
};
export default PricingCard;