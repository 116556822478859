import React, { useState } from "react";
import trustPilot from "@/assets/icons/trustpilot.svg";
import trustStars from "@/assets/icons/trust-stars.svg";
import client1 from "../../../assets/images/client1.png";
import client2 from "../../../assets/images/client2.png";
import client3 from "../../../assets/images/client3.png";
import style from "./clent-says.module.scss";
import Slider from "react-slick";
import Image from "next/image";
const ClientSays = () => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const slidesArray = [{
    text: "I cannot thank SustainBL enough for their incredible support throughout the IEP process. Having a dedicated professional by my side made a world of difference—I felt heard, informed, and empowered every step of the way. They streamlined the process, saving me countless hours and providing peace of mind so I could focus more on my family. Most importantly, my child now has access to the services they need to thrive. SustainBL has been a true partner in our journey, and I highly recommend their services to any parent navigating special education.",
    name: "---- Mike Anderson"
  }, {
    text: "I cannot thank SustainBL enough for their incredible support throughout the IEP process. Having a dedicated professional by my side made a world of difference—I felt heard, informed, and empowered every step of the way. They streamlined the process, saving me countless hours and providing peace of mind so I could focus more on my family. Most importantly, my child now has access to the services they need to thrive. SustainBL has been a true partner in our journey, and I highly recommend their services to any parent navigating special education.",
    name: "---- John Doe"
  }, {
    text: "I cannot thank SustainBL enough  navigating special education.",
    name: "---- Jane Smith"
  }];
  const goToNextSlide = () => {
    setCurrentSlide(prevSlide => (prevSlide + 1) % slidesArray.length);
  };
  const goToPreviousSlide = () => {
    setCurrentSlide(prevSlide => (prevSlide - 1 + slidesArray.length) % slidesArray.length);
  };
  return <div className={style.wrapper} data-sentry-component="ClientSays" data-sentry-source-file="index.jsx">
      <h1 className={style.heading}>
        WHAT
        <span style={{
        color: "#F2745C"
      }}> CLIENT SAYS</span>
      </h1>
      <div className={style.cardsDiv}>
        <div>
          <svg xmlns="http://www.w3.org/2000/svg" width="100" height="100" viewBox="0 0 100 100" fill="none" data-sentry-element="svg" data-sentry-source-file="index.jsx">
            <path d="M34.7412 50H19.4824V37.793C19.4824 31.06 24.9564 25.5859 31.6895 25.5859H33.2152C35.752 25.5859 37.793 23.5451 37.793 21.0082V11.8531C37.793 9.31641 35.7521 7.27539 33.2152 7.27539H31.6895C14.8285 7.27539 1.17188 20.932 1.17188 37.793V83.5693C1.17188 88.6238 5.27266 92.7246 10.3271 92.7246H34.7412C39.7957 92.7246 43.8965 88.6238 43.8965 83.5693V59.1553C43.8965 54.1008 39.7957 50 34.7412 50Z" fill="#254956" data-sentry-element="path" data-sentry-source-file="index.jsx" />
            <path d="M89.6729 50H74.4141V37.793C74.4141 31.06 79.8881 25.5859 86.6211 25.5859H88.1469C90.6836 25.5859 92.7246 23.5451 92.7246 21.0082V11.8531C92.7246 9.31641 90.6838 7.27539 88.1469 7.27539H86.6211C69.7602 7.27539 56.1035 20.932 56.1035 37.793V83.5693C56.1035 88.6238 60.2043 92.7246 65.2588 92.7246H89.6729C94.7273 92.7246 98.8281 88.6238 98.8281 83.5693V59.1553C98.8281 54.1008 94.7273 50 89.6729 50Z" fill="#254956" data-sentry-element="path" data-sentry-source-file="index.jsx" />
          </svg>
        </div>
        <div className={style.slidesToShow} style={{
        transform: `translateX(-${currentSlide * 100}%)`
      }}>
          {slidesArray.map((slide, index) => <div className={style.slide} key={index}>
              <p className={style.desc}>{slide.text}</p>
              <p className={style.clientName}>{slide.name}</p>
            </div>)}
        </div>
        <div className={style.arrowDiv}>
          <svg xmlns="http://www.w3.org/2000/svg" width="35" height="35" viewBox="0 0 35 35" fill="none" onClick={goToPreviousSlide} data-sentry-element="svg" data-sentry-source-file="index.jsx">
            <path d="M28.4375 17.5H6.5625M6.5625 17.5L14.7656 26.25M6.5625 17.5L14.7656 8.75" stroke="black" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round" data-sentry-element="path" data-sentry-source-file="index.jsx" />
          </svg>

          <svg xmlns="http://www.w3.org/2000/svg" width="35" height="35" viewBox="0 0 35 35" fill="none" onClick={goToNextSlide} data-sentry-element="svg" data-sentry-source-file="index.jsx">
            <path d="M6.5625 17.5H28.4375M28.4375 17.5L20.2344 8.75M28.4375 17.5L20.2344 26.25" stroke="black" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round" data-sentry-element="path" data-sentry-source-file="index.jsx" />
          </svg>
        </div>
      </div>
    </div>;
};
export default ClientSays;