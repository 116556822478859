import React from "react";
import fb from "@/assets/icons/facebook.svg";
import yt from "@/assets/icons/youtube.svg";
import style from "./social.module.scss";
import Image from "next/image";
const SocialSection = () => {
  return <div className={style.wrapper} data-sentry-component="SocialSection" data-sentry-source-file="index.jsx">
      <Image src={yt} alt="youtube" className={style.youtubeIcon} data-sentry-element="Image" data-sentry-source-file="index.jsx" />
      <Image src={fb} alt="facebook" className={style.facebookIcon} data-sentry-element="Image" data-sentry-source-file="index.jsx" />
    </div>;
};
export default SocialSection;