import React from "react";
import goldUnderline from "@/assets/icons/ggold-underline.svg";
import sustainLarge from "@/assets/icons/sustain-large.svg";
import style from "./sustain.module.scss";
import Image from "next/image";
const SustainBl = () => {
  return <div className={style.wrapper} data-sentry-component="SustainBl" data-sentry-source-file="index.jsx">
      <div className={style.textSection}>
        <h1 className={style.heading}>UNFULFILLED? STRUGGLING? ALONE?</h1>
        <p className={style.subheading}>
          WE'LL CHANGE THAT
          <Image src={goldUnderline} alt="goldUnderline" className={style.goldLine} data-sentry-element="Image" data-sentry-source-file="index.jsx" />
        </p>
      </div>
      <h1 className={style.heading}>INTRODUCING</h1>
      <Image src={sustainLarge} alt="sustain-large" className={style.sustainLogo} data-sentry-element="Image" data-sentry-source-file="index.jsx" />
    </div>;
};
export default SustainBl;