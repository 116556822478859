import React, { useState } from 'react';
import style from './faq.module.scss';
import plus from '../../../public/assets/icons/plus.svg';
import minus from '../../../public/assets/icons/minus.svg';
import Image from 'next/image';
const FaqSection = () => {
  const [openIndex, setOpenIndex] = useState(null);
  const toggle = index => {
    setOpenIndex(openIndex === index ? null : index);
  };
  return <div className={style.wrapperFaq} data-sentry-component="FaqSection" data-sentry-source-file="index.jsx">
      <h2 className={style.heading}>Frequently Asked Questions</h2>
      <div className={style.barsSection}>
        {items.map((item, index) => <div key={index} className={style.faqRow} style={{
        cursor: 'pointer'
      }} onClick={() => toggle(index)}>
            <div className={style.question}>
              <p>{item?.title}</p>
              <Image src={openIndex === index ? minus : plus} alt="plus" />
            </div>

            <div className={`${style.answer} ${openIndex === index && style.answerOpen}`}>
              SustainBL helps you build a sustainable life by offering
              personalized tips and resources to reduce waste, save energy, and
              make eco-friendly choices, making it easy to live sustainably
              every day.
            </div>
          </div>)}
      </div>
    </div>;
};
export default FaqSection;
let items = [{
  title: 'How does SustainBL help me build a sustainable life?',
  description: 'MCRO is a powerful SaaS platform that helps brands optimize their TikTok marketing through automation tools, affiliate outreach, and data-driven insights.'
}, {
  title: 'What makes SustainBL different from other coaching services?',
  description: 'MCRO is ideal for brands looking to drive sales through effective promotional strategies.'
}, {
  title: 'How soon can I expect to see results with SustainBL?',
  description: 'MCRO automates promotional efforts, streamlines affiliate outreach, and provides actionable analytics, to increase sales and engagement.'
}, {
  title: 'Does SustainBL offer a guarantee?',
  description: 'Yes, MCRO offers a free trial that allows users to explore all features before committing to a subscription.'
}, {
  title: 'Can SustainBL help with professional goals as well as personal growth?',
  description: 'To get started, simply sign up on our website, choose your plan, and follow the setup instructions to launch your TikTok marketing campaigns.'
}, {
  title: 'How do I get started with SustainBL?',
  description: 'To get started, simply sign up on our website, choose your plan, and follow the setup instructions to launch your TikTok marketing campaigns.'
}];