import React, { useEffect, useRef, useState } from 'react';
import style from './pricing.module.scss';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import PricingCard from '../pricing-card';
const PricingSection = () => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [startX, setStartX] = useState(0);
  const slidesRef = useRef(null);
  const slidesArray = pricingPackages;
  const goToNextSlide = () => {
    setCurrentSlide(prevSlide => (prevSlide + 1) % slidesArray.length);
  };
  const goToPreviousSlide = () => {
    setCurrentSlide(prevSlide => (prevSlide - 1 + slidesArray.length) % slidesArray.length);
  };

  // Swipe functionality
  const handleTouchStart = e => {
    const touchStart = e.touches[0].clientX;
    setStartX(touchStart);
  };
  const handleTouchEnd = e => {
    const touchEnd = e.changedTouches[0].clientX;
    const swipeDistance = touchEnd - startX;
    if (swipeDistance > 50) {
      goToPreviousSlide();
    } else if (swipeDistance < -50) {
      goToNextSlide();
    }
  };
  useEffect(() => {
    if (window.innerWidth <= 900) {
      const slideContainer = slidesRef.current;
      if (slideContainer) {
        slideContainer.addEventListener('touchstart', handleTouchStart);
        slideContainer.addEventListener('touchend', handleTouchEnd);
      }
    }
    return () => {
      const slideContainer = slidesRef.current;
      if (slideContainer) {
        slideContainer.removeEventListener('touchstart', handleTouchStart);
        slideContainer.removeEventListener('touchend', handleTouchEnd);
      }
    };
  }, [startX]);
  return <div className={style.wrapper} data-sentry-component="PricingSection" data-sentry-source-file="index.jsx">
      <div>
        <h2 className={style.heading}>
          CHOSE THE <span style={{
          color: '#F2745C'
        }}>PLAN</span> THAT FITS
          YOUR JOURNEY
        </h2>
        <p className={style.secondHeading}>
          Choose the Plan that Fits Your Journey.
        </p>
      </div>

      <div className={style.cardDivLarge}>
        {pricingPackages.map((ele, index) => <div key={index}>
            <PricingCard pack={ele?.pack} price={ele?.price} btnText={ele?.btnTxt} features={ele?.features} isPopular={ele?.isPopular} />
          </div>)}
      </div>

      <div className={style.cardsDiv} ref={slidesRef}>
        <div className={style.slidesToShow} style={{
        transform: `translateX(-${currentSlide * 100}%)`
      }}>
          {pricingPackages.map((ele, index) => <div className={style.slide} key={index}>
              <PricingCard features={ele?.features} pack={ele?.pack} price={ele?.price} btnText={ele?.btnTxt} isPopular={ele?.isPopular} s />
            </div>)}
        </div>
        <div className={style.arrowDiv}>
          <svg xmlns="http://www.w3.org/2000/svg" width="35" height="35" viewBox="0 0 35 35" fill="none" onClick={goToPreviousSlide} data-sentry-element="svg" data-sentry-source-file="index.jsx">
            <path d="M28.4375 17.5H6.5625M6.5625 17.5L14.7656 26.25M6.5625 17.5L14.7656 8.75" stroke="black" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round" data-sentry-element="path" data-sentry-source-file="index.jsx" />
          </svg>

          <svg xmlns="http://www.w3.org/2000/svg" width="35" height="35" viewBox="0 0 35 35" fill="none" onClick={goToNextSlide} data-sentry-element="svg" data-sentry-source-file="index.jsx">
            <path d="M6.5625 17.5H28.4375M28.4375 17.5L20.2344 8.75M28.4375 17.5L20.2344 26.25" stroke="black" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round" data-sentry-element="path" data-sentry-source-file="index.jsx" />
          </svg>
        </div>
      </div>
    </div>;
};
export default PricingSection;
const pricingPackages = [{
  pack: 'Single Session',
  price: '$227',
  btnTxt: 'GET INSTANT CLARITY',
  features: [<span key="single-1">
        <span>Personalised Guidance: </span>
        Get one-on-one coaching tailored to your specific needs and current
        challenges.
      </span>, <span key="single-2">
        <span>Quick Insights: </span>
        Ideal for gaining immediate feedback, clarity, or strategies on pressing
        issues.
      </span>, <span key="single-3">
        <span>Flexible Scheduling: </span>
        Book a session when it suits you, without a long-term commitment.
      </span>, <span key="single-4">
        <span>Focused Support: </span>
        Dive into specific areas of growth or challenges with targeted attention
        from an experienced coach.
      </span>, <span key="single-5">
        <span>Introductory Experience: </span>
        Perfect for first-time clients to experience coaching before committing
        to a package.
      </span>]
}, {
  pack: '6 Pack',
  price: '$227',
  btnTxt: 'INVEST IN YOURSELF',
  isPopular: true,
  features: [<span key="six-1">
        <span>Comprehensive Transformation: </span>
        Twelve sessions offer a full journey of self-discovery, goal-setting,
        and personal growth.
      </span>, <span key="six-2">
        <span>Alignment 101 Course Included: </span>
        Gain access to our foundational Alignment 101 course to deepen your
        self-awareness and purpose.
      </span>, <span key="six-3">
        <span>Sustained Accountability: </span>
        Long-term coaching fosters sustainable habits, mindset shifts, and
        lasting change.
      </span>, <span key="six-4">
        <span>Deep Relationship with Your Coach: </span>
        Build a trusting, collaborative relationship with your coach, who will
        be with you throughout your personal growth journey.
      </span>]
}, {
  pack: '12 Pack',
  price: '$227',
  btnTxt: 'START YOUR JOURNEY',
  features: [<span key="twelve-1">
        <span>Consistent Progress Tracking: </span>
        Regular sessions allow for tracking progress over time, ensuring you
        stay on course.
      </span>, <span key="twelve-2">
        <span>Community Access: </span>
        Join our exclusive Facebook coaching group for added peer support and
        inspiration.
      </span>, <span key="twelve-3">
        <span>Structured Growth: </span>
        Six sessions help you build foundational skills and mindset changes that
        lead to real progress.
      </span>, <span key="twelve-4">
        <span>Accountability Partner: </span>
        Frequent check-ins with your coach help you stay accountable to your
        goals and maintain momentum.
      </span>]
}];