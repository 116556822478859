import React from "react";
import greenFlower from "@/assets/icons/green-flower.svg";
import style from "./grey-banner.module.scss";
import Image from "next/image";
const GreyBanner = () => {
  return <div className={style.wrapper} data-sentry-component="GreyBanner" data-sentry-source-file="index.jsx">
      <div className={style.bg}>
        <div className={style.restrictingDiv}>
          <Image src={greenFlower} alt="greenFlower" data-sentry-element="Image" data-sentry-source-file="index.jsx" />
          <p>
            “ Guiding individuals on a journey of self-discovery to unlock their
            potential and build fulfilling relationships.”
          </p>
        </div>
      </div>
    </div>;
};
export default GreyBanner;