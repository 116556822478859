'use client';

import { useEffect, useState } from 'react';
import Hero from '@/components/home/hero';
import { FAQ } from '@/components/home/faq';
import SocialLinks from '@/components/home/sociallinks';
import Problems from '@/components/home/problems';
import Benefits from '@/components/home/benefits';
import Features from '@/components/home/landingfeatures';
import ClearPathShort from '@/components/home/clearpathshort';
import NexusShort from '@/components/home/nexusshort';
import PersonalStatementShort from '@/components/home/personalstatementshort';
import SocialProofCarousel from '@/components/home/socialproof-carousel';
import Pricing from '@/components/learn-more/pricing';
import IntroductionSection from '@/components/home/introductionsection';
import OthersCharges from '@/components/learn-more/otherscharge';
import WhatWeDontDoHome from '@/components/home/whatwedontdohome';
import PremiumBonuses from '@/components/home/premiumbonuses';
import Guarantee from '@/components/learn-more/guarantee';
import ClearPathLongWhite from '@/components/learn-more/clearpathwhite';
import NexusLongLight from '@/components/learn-more/nexuslonglight';
import SuccessFailureDialog from '@/components/learn-more/success-failure-dialog';
import ErrorDialog from '@/components/learn-more/ErrorDialog';
import CallToAction from '@/components/home/CallToAction';
import LearnMore from '@/components/home/learnmore';
import { useRouter } from 'next/navigation';
import { createClient } from '@/utils/supabase/client';
import { useSupabaseUser } from '@/lib/providers/supabase-user-provider';
import Countdown from '@/components/home/countdown';
import LandingPageHeader from '@/components/home/header';
import GuaranteeBanner from '@/components/home/guarantee-banner';
import ClientSays from '@/components/home/client-says';
import LifeCoaching from '@/components/home/life-coaching';
import SustainBl from '@/components/home/sustain-bl';
import Course from '@/components/home/course';
import GoldBanner from '@/components/home/gold-banner';
import AboutCoach from '@/components/home/about-coach';
import HelpSection from '@/components/home/help';
import GreyBanner from '@/components/home/grey-banner';
import NextStep from '@/components/home/next-step';
import PricingSection from '@/components/home/pricing-section';
import FaqSection from '@/components/home/faq-section';
import SocialSection from '@/components/home/social-section';
import Newsletter from '@/components/home/newsletter';
const supabase = createClient();
export default function Home() {
  const {
    user,
    isLoading
  } = useSupabaseUser();
  const router = useRouter();
  const [isErrorDialogOpen, setIsErrorDialogOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [successModal, setSuccessModal] = useState<boolean | string>('');
  const onError = (message: string) => {
    setErrorMessage(message);
    setIsErrorDialogOpen(true);
  };
  useEffect(() => {
    const checkAuth = async () => {
      if (isLoading) {
        return;
      }
      const {
        data: {
          user
        },
        error
      } = await supabase.auth.getUser();
      if (user) {
        router.push('/todos');
        return;
      }
    };
    checkAuth();
  }, [router, isLoading]);
  useEffect(() => {
    if (typeof window !== 'undefined') {
      // Extract the search parameters from the URL
      const searchParams = new URLSearchParams(window.location.search);
      const success = searchParams.get('success');
      const failed = searchParams.get('failed');
      const error = searchParams.get('error');
      failed && error && onError(error);
      setSuccessModal(success ? true : failed ? false : '');
    }
  }, []); // Only run once on component mount

  return (
    // <div className="flex-auto ">
    //   <Hero />
    //   <Countdown />
    //   <SocialProofCarousel />
    //   <Problems />
    //   <IntroductionSection />
    //   <CallToAction />
    //   <Benefits />
    //   <PremiumBonuses />
    //   <NexusLongLight />
    //   <ClearPathLongWhite />
    //   {/* <Features /> */}
    //   <OthersCharges />

    //   <Pricing />
    //   <Guarantee bgColor="bg-white" />
    //   {/* <WhatWeDontDoHome /> */}
    //   <SocialProofCarousel
    //     title="Verified Testimonials"
    //     bgColor="bg-backgoundPlatinum"
    //   />
    //   <span id="pricing-section2"></span>
    //   <Pricing />
    //   <Guarantee bgColor="bg-oxfordBlue" />
    //   <FAQ />      
    //   {/* <LearnMore /> */}
    //   <SocialLinks />

    //   {/* success and error modal */}
    //   {!!successModal && (
    //     <SuccessFailureDialog
    //       {...{
    //         isOpen: !!successModal,
    //         onOpenChange: setSuccessModal,
    //       }}
    //     />
    //   )}
    //   <ErrorDialog
    //     isOpen={isErrorDialogOpen}
    //     message={errorMessage || ''}
    //     onOpenChange={setIsErrorDialogOpen}
    //   />
    // </div>
    <>
      <LandingPageHeader data-sentry-element="LandingPageHeader" data-sentry-source-file="page.tsx" />
      <ClientSays data-sentry-element="ClientSays" data-sentry-source-file="page.tsx" />
      <LifeCoaching data-sentry-element="LifeCoaching" data-sentry-source-file="page.tsx" />
      <SustainBl data-sentry-element="SustainBl" data-sentry-source-file="page.tsx" />
      <Course data-sentry-element="Course" data-sentry-source-file="page.tsx" />
      <GoldBanner data-sentry-element="GoldBanner" data-sentry-source-file="page.tsx" />
      <HelpSection data-sentry-element="HelpSection" data-sentry-source-file="page.tsx" />
      <AboutCoach data-sentry-element="AboutCoach" data-sentry-source-file="page.tsx" />
      <GreyBanner data-sentry-element="GreyBanner" data-sentry-source-file="page.tsx" />
      <NextStep data-sentry-element="NextStep" data-sentry-source-file="page.tsx" />
      <PricingSection data-sentry-element="PricingSection" data-sentry-source-file="page.tsx" />
      <GuaranteeBanner data-sentry-element="GuaranteeBanner" data-sentry-source-file="page.tsx" />
      <FaqSection data-sentry-element="FaqSection" data-sentry-source-file="page.tsx" />
      <Newsletter data-sentry-element="Newsletter" data-sentry-source-file="page.tsx" />
      <SocialSection data-sentry-element="SocialSection" data-sentry-source-file="page.tsx" />
    </>
  );
}