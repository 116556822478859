import React from "react";
import swirl from "@/assets/icons/swirl.svg";
import heart from "@/assets/icons/heart.svg";
import orangeFlower from "@/assets/icons/orange-flower.svg";
import style from "./gold-banner.module.scss";
import Image from "next/image";
const GoldBanner = () => {
  return <div className={style.wrapper} data-sentry-component="GoldBanner" data-sentry-source-file="index.jsx">
      <div className={style.bg}>
        <div className={style.restrictingDiv}>
          {/* absolute */}
          <Image src={swirl} className={style.swirlSvg} alt="swirl" data-sentry-element="Image" data-sentry-source-file="index.jsx" />
          {/* div holding content */}
          <div className={style.content}>
            <div>
              <Image src={orangeFlower} alt="orangeFlower" className={style.leftImage} data-sentry-element="Image" data-sentry-source-file="index.jsx" />
            </div>
            <div>
              <h2 className={style.headingRight}>
                Let me teach you how to find (love?) yourself.
              </h2>
              <ul className={style.ulClass}>
                <li>
                  You are more than the things you do, you are more than your
                  past experiences.
                </li>
                <li>
                  Whether you feel lost, frustrated, unfulfilled, or just trying
                  to find your higher purpose
                </li>
                <li>
                  We'll dive into the depths of your core – the real, aligned,
                  purposeful you.
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>;
};
export default GoldBanner;