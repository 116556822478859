import React from "react";
import client from "@/assets/images/client.png";
import circle from "@/assets/icons/circle.svg";
import style from "./about-coach.module.scss";
import Image from "next/image";
import Button from "../button";
const AboutCoachBanner = () => {
  return <div className={style.wrapper} data-sentry-component="AboutCoachBanner" data-sentry-source-file="index.jsx">
            <div className={style.bg}>
                <div className={style.restrictingDiv}>
                    <div className={style.content}>
                        <div className={style.clientImage}><Image src={client} data-sentry-element="Image" data-sentry-source-file="index.jsx" /></div>
                        <div className={style.rightDiv}>
                            <div className={style.heading}>
                                <div className={style.upperLine} />
                                <span className={style.aboutText}>About your coach</span>
                                <span className={style.nameText}>BRYAN DAVIS</span>
                                <div className={style.lowerLine} />
                            </div>
                            <div className={style.peragraphDiv}>
                                <p>I am a product designer with two decades of expertise in crafting solutions that genuinely make a difference in people's lives.</p>
                                <p>My journey into the realm of special education began a few years back, sparked by a personal connection - a family member struggling academically due to behavioral challenges.</p>
                                <p>This experience opened my eyes to both the strengths and shortcomings of the current special education system in our schools. Witnessing firsthand the ups and downs, I've gained valuable insights into where the system excels and where it falls short.</p>
                            </div>
                            <div className={style.btnDiv}>
                                <Image src={circle} className={style.circleIcon} data-sentry-element="Image" data-sentry-source-file="index.jsx" />
                                <Button label={"GET IN CONTACT NOW"} className={style.btnClass} data-sentry-element="Button" data-sentry-source-file="index.jsx" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>;
};
export default AboutCoachBanner;