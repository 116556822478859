import React from 'react';
import calender from '@/assets/icons/step-calender.svg';
import community from '@/assets/icons/step-community.svg';
import yourself from '@/assets/icons/step-yourself.svg';
import style from './next-step.module.scss';
import Button from '@/components/home/button';
import Image from 'next/image';
const NextStep = () => {
  return <div className={style.wrapper} data-sentry-component="NextStep" data-sentry-source-file="index.jsx">
      <div>
        <h2 className={style.heading}>
          READY TO TAKE <span style={{
          color: '#F2745C'
        }}>NEXT STEP</span>?
        </h2>
        <p className={style.secondHeading}>
          The struggle is real. We’re here to help.
        </p>
      </div>
      <div className={style.stepsCardDiv}>
        {oranges.map((e, index) => <div className={style.orangeCard} key={index}>
            <Image src={e?.icon} alt={`orange-icon-${index}`} />
            <p className={style.orangeHeading}>{e.heading}</p>
            <p className={style.orangeSubHeading}>{e.subHeading}</p>
          </div>)}
      </div>
      <Button className={style.btn} label={'GET STARTED NOW'} data-sentry-element="Button" data-sentry-source-file="index.jsx" />
    </div>;
};
export default NextStep;
const oranges = [{
  icon: calender,
  heading: 'Schedule a Free Consultation:',
  subHeading: 'Discover how coaching can transform your life.'
}, {
  icon: community,
  heading: 'Join Our Community:',
  subHeading: 'Connect with like-minded individuals and receive inspiring content.'
}, {
  icon: yourself,
  heading: 'Invest in Yourself',
  subHeading: 'Enroll in one of our transformative programs'
}];